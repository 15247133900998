.sidebar {
	.nav-link i {
		color:black;
	}
}
.dashmenu {
	.nav {
		margin-left:1em;
	}
	.nav-link {
		i {
			color: currentColor;
			margin-right: 15px;
			color: gray('500');
			flex-shrink: 0;
		}

		&:hover {
			font-weight: 600;
			color: theme-color('primary');
			i {
			  	color: theme-color('primary');
			}
		}

		&.active {
			font-weight: 600;
			color: theme-color('primary');
			i {
				color: theme-color('primary');
			}
		}
	}
}