.user-bubble {
	border-radius:20px;
	display:flex;
	align-items: center;
	background: white;
	box-shadow:3px 3px 0px #cccccc;
	padding:2px;
	& + & {
		margin-top:3px;
	}
}
.user-bubble__img {
	width:30px;
	height:auto;
	border-radius:50%;
	margin-right:5px;
	flex:0 0 auto;
}