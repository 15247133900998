// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }
  }
}

// Custom
@import "custom";

@import 'base/resets';

@import 'components/sidebar';
@import 'components/user-bubble';
@import 'components/table';
@import 'components/alert';

@import '~bootstrap-vue/src/index.scss';

// Vendor
@import '~select2/src/scss/core';
@import '~flatpickr/dist/flatpickr.css';
@import '~@ttskch/select2-bootstrap4-theme/src/select2-bootstrap4';